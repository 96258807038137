import React from 'react';
import './information.css';

function Information() {
    return (
        <div className='info'>
            <h2>Qu'est-ce que le Préfabriqué ?</h2>
            <p>
                Les préfabriqués sont des bâtiments dont les composants sont fabriqués en usine avant d'être assemblés sur le site de construction. 
                Ce processus permet de réduire les délais et de maintenir un haut niveau de qualité.
            </p>
            <h3>Utilité des Préfabriqués</h3>
            <p>
                Les préfabriqués sont rapides à construire, économes, et offrent une grande flexibilité. Ils répondent aux besoins des particuliers 
                comme des entreprises cherchant une solution fiable et abordable pour construire des maisons, bureaux, et autres structures.
            </p>
            <h3>Domaine d'Application</h3>
            <p>
                Les bâtiments préfabriqués sont utilisés dans des domaines variés : résidentiel, commercial, industriel et public. On les retrouve sous forme de 
                maisons modulaires, de bureaux modernes, et même de salles de classe ou d’hôpitaux temporaires.
            </p>
        </div>
    );
}

export default Information;
