import React from 'react';
import Presentation from './Presentation';
import ProductDisplay from './ProducDisplay';

function Product(props) {
    return (
        <div>
            <Presentation />
            <ProductDisplay />
        </div>
    );
}

export default Product;