import React, { useState } from 'react';
import axios from 'axios';
import './adminarticule.css';

function AdminArticule({ onArticleAdded }) {
  const [title, setTitle] = useState('');
  const [domain, setDomain] = useState('');
  const [content, setContent] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState('');

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('domain', domain);
    formData.append('content', content);
    formData.append('image', image);

    try {
      await axios.post('http://localhost:5000/addarticles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
        },
      });

      setMessage("L'article a été ajouté avec succès !");
      setTitle('');
      setDomain('');
      setContent('');
      setImage(null);

      // Notifier le composant parent que l'article a été ajouté
      if (onArticleAdded) {
        onArticleAdded();
      }
    } catch (error) {
      setMessage("Erreur lors de l'ajout de l'article.");
      console.error(error);
    }
  };

  return (
    <div className="admin-articule">
      <h2>Ajouter un nouvel article</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Titre :
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </label>
        <label>
          Domaine :
          <input type="text" value={domain} onChange={(e) => setDomain(e.target.value)} required />
        </label>
        <label>
          Contenu :
          <textarea value={content} onChange={(e) => setContent(e.target.value)} required />
        </label>
        <label>
          Image :
          <input type="file" onChange={handleImageChange} accept="image/*" />
        </label>
        <button type="submit">Ajouter l'article</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default AdminArticule;
