import React, { useState } from 'react';
import './help.css';
import { db } from '../../login/firebaseConfig'; // Assurez-vous que Firebase est configuré dans ce fichier
import { collection, addDoc } from 'firebase/firestore';

function Help() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, 'contacts'), {
                name: name,
                email: email,
                message: message,
                date: new Date(),
            });
            setStatus('Message envoyé avec succès !');
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            setStatus('Une erreur est survenue, veuillez réessayer.');
        }
    };

    return (
        <div className="help-container">
            <h1>Contactez-nous</h1>
            
            <div className="contact-info">
                <h2>Nos Coordonnées</h2>
                <p><strong>Adresse :</strong> Cyber Parc, Sidi Abdellah N°CA-E1-M16, ALGER, ALGER 16 000, DZ</p>
                <p><strong>Téléphone :</strong> +213 023 09 31 70</p>
                <p><strong>Email :</strong> contact@concrast.com</p>
                <p><strong>Horaires :</strong> Du lundi au vendredi, de 9h à 17h</p>
            </div>
            
            <form className="contact-form" onSubmit={handleSubmit}>
                <h2>Envoyez-nous un message</h2>
                <label>
                    Nom :
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Email :
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <label>
                    Message :
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    ></textarea>
                </label>
                <button type="submit">Envoyer</button>
                {status && <p className="status-message">{status}</p>}
            </form>
        </div>
    );
}

export default Help;
