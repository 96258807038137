import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Shop from './Shop';
import Navbar from './componates/navbar/Navbar';
import Login from './componates/login/Login';
import Prefabrique from './componates/prefabrique/Prefabrique';
import Merci from './componates/merci/Merci';

import { useEffect, useState } from 'react';
import { auth, db } from './componates/login/firebaseConfig'; // Assurez-vous d'importer auth et db
import { doc, getDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Admin from './componates/admin/Admin';

import About from './componates/abdout/About';

import Product from './componates/product/Product';
import Projectform from './componates/projectform/Projectform';


function App(props) {

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Observer l'état de l'utilisateur
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        // Récupérer les informations de l'utilisateur dans Firestore
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          // Vérifiez si l'utilisateur a le rôle d'administrateur
          setIsAdmin(userData.role === 'admin');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe(); // Nettoie l'observateur
  }, []);

  if (loading) {
    return <p>Chargement...</p>; // Affichage pendant le chargement
  }

  return (
    <div>
      <BrowserRouter>
      <Navbar/>
      <Routes>
      <Route path='/' element={<Shop />} />
      <Route path='/Préfabriqué' element={<Prefabrique />} />
      <Route path='/about' element={<About />} />
      <Route path='/services' element={<Product />} />
      <Route path='/projectform' element={<Projectform />} />
      <Route path='/merci' element={<Merci />} />

      <Route path='/login' element={<Login />} />
     

      <Route 
            path='/admin' 
            element={isAdmin ? <Admin /> : <Navigate to="/login" />} 
          />

      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;