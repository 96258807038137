import React from 'react';
import './Presentation.css';

function Presentation() {
    return (
        <div className="concrast-container">
            <h2>Découvrez les meilleurs produits de Concrast</h2>
            <p className="intro-text">
                Concrast s'engage à offrir des solutions en béton préfabriqué qui allient innovation, solidité, et durabilité. Avec des décennies d'expérience et une expertise en ingénierie de pointe, nous transformons vos projets de construction en réalité, avec une précision inégalée.
            </p>
            <p className="details-text">
                Nous comprenons que chaque projet est unique. C'est pourquoi notre équipe d'experts collabore étroitement avec vous pour concevoir des structures sur mesure qui respectent vos spécifications, votre budget et vos délais. Chez Concrast, nous repoussons les limites de la technologie pour vous offrir des solutions performantes et sécurisées.
            </p>
            <div className="features-container">
                <div className="feature-card">
                    <span className="icon">🏗️</span>
                    <h3>Innovation Technique</h3>
                    <p>Des procédés avancés pour répondre aux défis les plus complexes.</p>
                </div>
                <div className="feature-card">
                    <span className="icon">🛠️</span>
                    <h3>Ingénierie de Précision</h3>
                    <p>Une expertise sans faille pour garantir la robustesse de chaque structure.</p>
                </div>
                <div className="feature-card">
                    <span className="icon">🔒</span>
                    <h3>Fiabilité et Sécurité</h3>
                    <p>Des normes de sécurité rigoureuses pour assurer la longévité des projets.</p>
                </div>
                <div className="feature-card">
                    <span className="icon">🌱</span>
                    <h3>Écoresponsabilité</h3>
                    <p>Des solutions durables qui respectent l'environnement.</p>
                </div>
                <div className="feature-card">
                    <span className="icon">⏱️</span>
                    <h3>Respect des Délais</h3>
                    <p>Des projets livrés à temps, sans compromis sur la qualité.</p>
                </div>
                <div className="feature-card">
                    <span className="icon">🏆</span>
                    <h3>Excellence Garantie</h3>
                    <p>Des produits de haute qualité, certifiés et testés pour la performance.</p>
                </div>
            </div>
        </div>
    );
}

export default Presentation;
