import React, { useState } from "react";
import UserManagement from "./userManager/UserManagement";
import AdminArticule from "./articule/AdminArticule";
import Allarticule from "./allarticule/Allarticule";
import AdminContact from "./adminContact/AdminContact";
import './admin.css';
import AddProductAdmin from "./productadmin/AddProductAdmin";
import AllProductAdmin from "./productadmin/allproduct/AllProductAdmin";
import Commande from "./comande/Comande";

function Admin() {
  const [activeComponent, setActiveComponent] = useState("users");
  const [refreshArticles, setRefreshArticles] = useState(false);

  // Fonction pour forcer l'actualisation des articles
  const handleArticleAdded = () => {
    setRefreshArticles(!refreshArticles);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case "users":
        return <UserManagement />;
      case "articule":
        return (
          <>
            <AdminArticule onArticleAdded={handleArticleAdded} />
            <Allarticule refresh={refreshArticles} />
            
          </>
        );
      case "contact":
        return <AdminContact />;
        case "product":
          return (
            <>
            <AddProductAdmin />
            <AllProductAdmin />
            </>
          )
          case"commande":
          return <Commande />
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="admin-container">
      <nav>
        <button
          onClick={() => setActiveComponent("users")}
          className={activeComponent === "users" ? "active" : ""}
        >
          Gestion des Utilisateurs
        </button>
        <button
          onClick={() => setActiveComponent("articule")}
          className={activeComponent === "articule" ? "active" : ""}
        >
          Gestion des articles
        </button>
        <button
          onClick={() => setActiveComponent("contact")}
          className={activeComponent === "contact" ? "active" : ""}
        >
          Gestion des Contacts
        </button>

        <button
          onClick={() => setActiveComponent("product")}
          className={activeComponent === "product" ? "active" : ""}
        >
          Gestion des Produits
        </button>

        <button
          onClick={() => setActiveComponent("commande")}
          className={activeComponent === "commande" ? "active" : ""}
        >
          Gestion des Commandes
        </button>



      </nav>
      <div className="admin-content">{renderComponent()}</div>
    </div>
  );
}

export default Admin;
