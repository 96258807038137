import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore"; // Import Firestore utils
import { auth, db } from "../login/firebaseConfig"; // Importer auth et db
import logo from "../assets/IMG-20241107-WA0017-removebg-preview.png";
import "./navbar.css";

function Navbar() {
  const [isMobile, setIsMobile] = useState(false);
  const [user, setUser] = useState(null); // Utilisateur connecté
  const [isAdmin, setIsAdmin] = useState(false); // Vérifier si l'utilisateur est admin
  const navigate = useNavigate();

  // Vérifie si un utilisateur est connecté
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setIsAdmin(userData.role === "admin"); // Vérifier si l'utilisateur est admin
          }
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des informations utilisateur :",
            error
          );
        }
      } else {
        setIsAdmin(false); // Réinitialiser si aucun utilisateur
      }
    });

    return () => unsubscribe();
  }, []);

  // Fonction pour gérer la déconnexion
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login"); // Redirige vers la page de login après déconnexion
    } catch (error) {
      console.error("Erreur de déconnexion :", error);
    }
  };

  // Gère le clic sur les liens du menu
  const toggleMenu = () => {
    if (isMobile) setIsMobile(false);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="SPACE SORTIUM" />
      </div>
      <ul
        className={isMobile ? "navbar-menu-mobile active" : "navbar-menu"}
        onClick={toggleMenu}
      >
        <li>
          <Link to="/">Accueil</Link>
        </li>
        <li>
          <Link to="/services">Services</Link>
        </li>
        <li>
          <Link to="/Préfabriqué">Préfabriqué</Link>
        </li>
        <li>
          <Link to="/about">À propos de nous</Link>
        </li>
        {isAdmin && (
          <li>
            <Link to="/admin">Admin</Link>
          </li>
        )}
        <li>
          {user ? (
            <button onClick={handleLogout} className="logout-button">
              Déconnexion
            </button>
          ) : (
            <Link to="/login">Espace client</Link>
          )}
        </li>
      </ul>
      <button
        className="mobile-menu-icon"
        onClick={() => setIsMobile(!isMobile)}
      >
        {isMobile ? <>&#10005;</> : <>&#9776;</>}
      </button>
    </nav>
  );
}

export default Navbar;
