import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Importer useNavigate
import "./productdisplay.css";

function ProductDisplay() {
  const [products, setProducts] = useState([]); // État pour stocker les produits
  const [error, setError] = useState(""); // État pour gérer les erreurs
  const navigate = useNavigate(); // Initialisation de useNavigate

  // Fonction pour récupérer les produits depuis le back-end
  const fetchProducts = async () => {
    try {
      const response = await axios.get("http://localhost:5000/products"); // URL de ton back-end
      setProducts(response.data); // Met à jour l'état avec les produits récupérés
    } catch (err) {
      setError("Erreur lors de la récupération des produits.");
      console.error(err);
    }
  };

  // Charger les produits lorsque le composant est monté
  useEffect(() => {
    fetchProducts();
  }, []); // L'appel API ne sera effectué qu'une seule fois au montage du composant

  // Fonction pour gérer le clic sur "Acheter Maintenant"
  const handleBuyNow = (productId, productName) => {
    navigate("/projectform", { state: { productId, productName } }); // Redirige vers /projectform avec les données
  };

  return (
    <div className="product-display-container">
      <h2 className="title">Nos Produits</h2>
      {error && <p className="error">{error}</p>}
      <div className="product-grid">
        {products.length > 0 ? (
          products.map((product) => (
            <div key={product._id} className="product-card">
              <div className="product-image-container">
                <img
                  src={`http://localhost:5000${product.image}`}
                  alt={product.name}
                  className="product-image"
                />
              </div>
              <div className="product-info">
                <h3 className="product-name">Produit: {product.name}</h3>
                <p className="product-category">Catégorie: {product.category}</p>
                <p className="product-description">
                  Description: {product.description}
                </p>
                <p>material utlise: {product.material}</p>
                <div className="product-footer">
                  <span className="product-price">Prix: {product.price} €</span>
                  <button
                    className="add-to-cart-btn"
                    onClick={() => handleBuyNow(product._id, product.name)} // Appeler handleBuyNow au clic
                  >
                    Acheter Maintenant
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>Aucun produit disponible.</p>
        )}
      </div>
    </div>
  );
}

export default ProductDisplay;
