// firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // Firestore pour la base de données
import { getAuth, GoogleAuthProvider } from "firebase/auth"; // Authentification
import { getAnalytics } from "firebase/analytics";

// Configuration de votre application Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAwqbN7tjcHb0nT2Ucn1Pzi7K36W47duWI",
  authDomain: "concraste.firebaseapp.com",
  projectId: "concraste",
  storageBucket: "concraste.firebasestorage.app",
  messagingSenderId: "329756791432",
  appId: "1:329756791432:web:74bbc65e329589b855c53d",
  measurementId: "G-VSWNGV3JGY"
};

// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser les services Firebase
const analytics = getAnalytics(app); // Pour l'analytics (optionnel)
const db = getFirestore(app); // Pour Firestore
const auth = getAuth(app); // Pour l'authentification
const googleProvider = new GoogleAuthProvider(); // Pour l'authentification Google

// Exportez les services nécessaires
export { app, analytics, db, auth, googleProvider };
