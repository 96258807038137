import React, { useState } from 'react';
import './questions.css';

function Questions() {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAnswer = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "Quels sont les principaux domaines d'expertise de CONCRAST ?",
            answer: "CONCRAST est spécialisé dans le préfabriqué, l'ingénierie et les solutions en béton. L'entreprise maîtrise les techniques de préfabrication et propose des solutions sur mesure pour répondre aux besoins de projets complexes."
        },
        {
            question: "Quels types de projets CONCRAST peut-il prendre en charge ?",
            answer: "CONCRAST peut prendre en charge une grande variété de projets, allant des grands projets d'infrastructure aux constructions sur mesure. L'entreprise s'adapte à chaque projet pour offrir des solutions personnalisées."
        },
        {
            question: "Quels sont les avantages de choisir CONCRAST pour un projet ?",
            answer: "En choisissant CONCRAST, vous bénéficiez d'une expertise reconnue en ingénierie, de solutions sur mesure, d'une grande qualité de construction et d'un respect strict des normes de sécurité et de performance."
        },
        {
            question: "Comment se déroule la collaboration avec CONCRAST ?",
            answer: "CONCRAST adopte une approche collaborative avec ses clients. L'entreprise prend le temps d'écouter vos besoins et de vous proposer des solutions adaptées. Elle vous accompagne tout au long du projet, de la conception à la réalisation."
        },
        {
            question: "Quelles sont les valeurs de CONCRAST ?",
            answer: "CONCRAST est une entreprise engagée dans l'innovation et l'efficacité. Elle met un point d'honneur à livrer des projets de qualité, dans les délais impartis et en respectant les normes les plus strictes."
        }
    ];

    return (
        <div className="faq-container">
            <h3>Questions Fréquemment Posées sur CONCRAST</h3>
            {faqData.map((item, index) => (
                <div className="question-item" key={index}>
                    <h4 onClick={() => toggleAnswer(index)}>
                        {item.question}
                    </h4>
                    <div className={`answer ${activeIndex === index ? 'show' : ''}`}>
                        {item.answer}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Questions;