import React from 'react';
import banner from '../assets/banner.webp';
import './about.css';
import Help from './help/Help';

function About() {
    return (
        <div className="about-container">
            <img src={banner} alt="Concrast Banner" className="about-banner" />
            
            <section className="about-content">
                <h1>À propos de Concrast</h1>
                <p>
                    Concrast est une entreprise de premier plan dans le domaine des solutions en béton préfabriqué. 
                    Nous sommes fiers de notre expertise en ingénierie et de notre capacité à offrir des solutions personnalisées 
                    pour des projets de construction complexes. Grâce à des années d'expérience et à une approche innovante, 
                    nous avons construit une réputation solide dans l'industrie.
                </p>
                
                <h2>Notre Mission</h2>
                <p>
                    Chez Concrast, notre mission est de fournir des solutions de béton préfabriqué de haute qualité, 
                    conçues pour durer et répondre aux exigences les plus élevées en matière de sécurité et de performance. 
                    Nous nous engageons à transformer les idées de nos clients en réalité, avec des structures qui défient 
                    le temps et les éléments.
                </p>
                
                <h2>Pourquoi nous choisir ?</h2>
                <ul className="about-list">
                    <li>Produits durables et robustes pour une sécurité maximale.</li>
                    <li>Solutions sur mesure adaptées à chaque projet.</li>
                    <li>Équipe d'experts en ingénierie et en gestion de projet.</li>
                    <li>Respect des délais et des budgets.</li>
                    <li>Utilisation de techniques de pointe pour une précision optimale.</li>
                </ul>

                <h2>Nos valeurs</h2>
                <p>
                    Nous croyons en l'intégrité, la qualité et l'innovation. Ces valeurs sont au cœur de chaque projet 
                    que nous entreprenons. Chez Concrast, nous nous efforçons de dépasser les attentes de nos clients 
                    et de contribuer à la construction d'un avenir plus solide.
                </p>
            </section>
            <h1>Vous Avez besoin De Aide Contact Nous </h1>
            <Help />
            
        </div>
    );
}

export default About;
