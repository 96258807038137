import React from "react";
import poutre from '../assets/Poutre Préfabriquée.webp'
import mur from "../assets/Mur de Soutènement.webp";
import { Link } from "react-router-dom";
import './affiche.css';

function Affiche(props) {
  const produits = [
    {
      titre: "Poutre Préfabriquée",
      description:
        "Une poutre en béton préfabriquée, idéale pour des structures résistantes et durables. Conçue pour s’adapter aux projets d’infrastructure les plus exigeants.",
      image: poutre,
    },
    {
      titre: "Mur de Soutènement",
      description:
        "Un mur de soutènement en béton, offrant stabilité et sécurité pour les aménagements paysagers et les infrastructures nécessitant un soutien fiable.",
      image: mur,
    },
  ];

  return (
    <div className="product-container">
      {produits.map((produit, index) => (
        <div key={index} className="product-card">
          <img src={produit.image} alt={produit.titre} className="product-image" />
          <h3 className="product-title">{produit.titre}</h3>
          <p className="product-description">{produit.description}</p>
          <Link to="/services" ><button> En Savoir Plus</button> </Link>
        </div>
      ))}
    </div>
  );
}

export default Affiche;
