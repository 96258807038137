import React from "react";
import Information from "./articule/Information";

import Articule from "./articule/Articule";
import './prefabrique.css'

function Prefabrique() {
  return (
    <div className="prefabrique-container">
      <Information />
      <Articule />
      
      
    </div>
  );
}

export default Prefabrique;
