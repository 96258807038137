import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Importer useNavigate
import { db } from '../login/firebaseConfig'; // Assurez-vous d'importer votre configuration Firebase
import { collection, addDoc } from 'firebase/firestore';
import './projectform.css';

function Projectform() {
  const location = useLocation(); // Utilisé pour récupérer les données passées
  const navigate = useNavigate(); // Utilisé pour la redirection
  const { productId, productName } = location.state || {}; // Récupère l'ID et le nom du produit

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    details: productName || '', // Utilise le nom du produit comme détail par défaut
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'commandes'), { ...formData, productId }); // Ajoute l'ID du produit aux données
      alert('Commande enregistrée avec succès !');
      setFormData({ name: '', email: '', phone: '', details: productName || '' });
      navigate('/merci'); // Redirige vers la route "/merci"
    } catch (error) {
      console.error('Erreur lors de la création de la commande :', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  return (
    <div className="form-container">
      <h2>Passer une commande</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="name">Nom :</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email :</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="phone">Numéro de téléphone :</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        <label htmlFor="details">Produit sélectionné :</label>
        <input
          type="text"
          id="details"
          name="details"
          value={formData.details}
          readOnly
        />

        <button type="submit">Envoyer</button>
      </form>
    </div>
  );
}

export default Projectform;
