import React, { useState } from 'react';
import Accueil from './componates/accueil/Accueil';
import Affiche from './componates/affiche/Affiche';
import './shop.css'

import Questions from './componates/questions/Questions';

import Contcat from './componates/contact/Contact';

function Shop(props) {

     // État pour gérer le mot de passe et l'affichage
     const [password, setPassword] = useState("");
     const [isAuthorized, setIsAuthorized] = useState(false);
 
     // Mot de passe correct
     const correctPassword = "motdepasse123"; // Remplacez par votre mot de passe
 
     const handlePasswordChange = (e) => {
         setPassword(e.target.value);
     };
 
     const handlePasswordSubmit = () => {
         if (password === correctPassword) {
             setIsAuthorized(true);
         } else {
             alert("Mot de passe incorrect !");
         }
     };
 
     // Si l'utilisateur n'est pas autorisé, affiche le champ de mot de passe
     if (!isAuthorized) {
         return (
             <div className="password-protection">
                 <h2>Entrez le mot de passe pour accéder au site :</h2>
                 <input
                     type="password"
                     value={password}
                     onChange={handlePasswordChange}
                     placeholder="Mot de passe"
                 />
                 <button onClick={handlePasswordSubmit}>Soumettre</button>
             </div>
         );
     }
 
    return (
        <div>
            <Accueil />
            <h1 className='mid'>Nos meilleurs produits</h1>
            <Affiche />

            <Questions />
            <Contcat />
        </div>
    );
}

export default Shop;