import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Articule() {
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState('');

  const fetchArticles = async () => {
    try {
      const response = await axios.get('http://localhost:5000/articles');
      setArticles(response.data);
    } catch (err) {
      setError("Erreur lors de la récupération des articles.");
      console.error(err);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <div className="all-articule">
      <h2>Tous les articles</h2>
      {error && <p className="error">{error}</p>}
      <div className="articles-list">
        {articles.map((article) => (
          <div key={article._id} className="article-item">
            <h3>{article.title}</h3>
            <p><strong>Domaine :</strong> {article.domain}</p>
            {article.image && (
              <img
                src={`http://localhost:5000${article.image}`}
                alt={article.title}
              />
            )}
            <p>Contenu de l'article : {article.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Articule;
