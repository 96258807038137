import React from 'react';
import './accueil.css';
import banner from '../assets/intro-img.webp'; // Update the path to your new banner image

function Accueil(props) {
    return (
        <div className='accueil'>
            <div className='banner'>
                <img src={banner} alt="Bannière de Concrast" />
            </div>
            <div className='presentation'>
                <h1>Présentation de Concrast</h1>
                <p>
                    Concrast est une entreprise spécialisée dans les solutions en béton préfabriqué, offrant une expertise en ingénierie et des solutions sur mesure pour des projets de construction complexes. Basée sur des techniques innovantes et une longue expérience, Concrast répond aux attentes les plus exigeantes du secteur.
                </p>
                <p>
                    Avec un engagement envers l’excellence, nous fournissons des structures solides et durables, optimisées pour la sécurité et la performance. Concrast est le partenaire idéal pour vos projets d’infrastructure, offrant des produits de haute qualité fabriqués avec précision et durabilité.
                </p>
                <p>
                    Notre équipe travaille en étroite collaboration avec nos clients pour garantir des solutions sur mesure qui respectent les délais et les budgets. Que ce soit pour des projets d'envergure ou des constructions spécifiques, Concrast est là pour concrétiser vos idées avec une approche collaborative et une exécution impeccable.
                </p>
            </div>
        </div>
    );
}

export default Accueil;
